@import url('./colors.css');

body {
    --font: Poppins, sans-serif;
    --t-size-1: 12px;
    --t-size-2: 14px;
}

@media (min-width: 1400px) {
    body {
        --t-size-1: 13px;
        --t-size-2: 15px;
    }
}

@media (min-width: 1700px) {
    body {
        --t-size-1: 18px;
        --t-size-2: 20px;
    }
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    width: 100%;
    height: 100%;  

    font-family: var(--font);

    overflow-x: hidden;
    overflow-y: hidden;
    
}

.body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

* {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    outline: none;
}
*::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

@media (max-width: 1100px) {
    body {
        overflow-y: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    body::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
}


/* Hide ugly scrollbar */
.hide-scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}