body {

    /* DARK MODE*/
    
    /* Colored text colors */
    --red: #e73835;
    --green: #2ed798;
    --purple: #a800ff;
    --cyan: #00deff;
    --orange: #ff9c00;

    /* Base text colors */
    color: white;
    --t-color-1: #ffffff;
    --t-color-2: #6a7fae;
    --t-color-3: #d1c7c8;

    /* Base background colors */
    --b-color-1: #11141b;
    --b-color-2: #1c202c;
    --b-color-3: #1c212d;
    --b-color-4: #222837;
    --b-color-5: #293042;

    --g-purple: #3b2752;
    /* Invert image colors */
    --invert: 0;
}

.--light {

    /* LIGHT MODE*/
    
    /* Colored text colors */
    --red: #b81714;
    --green: #13a36e;
    --purple: #751fa0;
    --cyan: #159baf;
    --orange: #c27f12;

    /* Base text colors */
    color: black;
    --t-color-1: #000000;
    --t-color-2: #333333;
    --t-color-3: #555555;

    /* Base background colors */
    --b-color-1: #ffffff;
    --b-color-2: #d4ece3;
    --b-color-3: #f7f7f7;
    --b-color-4: #c5eee4;
    --b-color-5: #ffffff;
    
    --g-purple: #986cca;

    /* Invert image colors */
    --invert: 1;
}

i {
    color: var(--t-color-1) !important;
}

img {
    filter: invert(var(--invert));
}

.static-color {
    filter: invert(0);
}

* {
    transition: background-color 300ms ease-in-out;
}