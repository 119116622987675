.landing {
    height: 100vh;
    width: 100vw;
    background-color: #1d212d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home {
    position: fixed;
    right: 5px;
    top: 5px;
}

.card {
    max-height: 90%;
    width: 90%;
    max-width: 500px;
    background-color: #1f2a49;
    overflow: scroll;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px 0 rgb(0, 0, 0, 0.05);
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.header {
    font-size: 22px;
    text-align: center;
    display: flex;
    align-items:flex-end;
    justify-content: center;
}

.sub-header {
    font-size: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 125px;
    width: 100%;
    position: relative;
}

.logo__svg {
    position: absolute;
    height: 50px;
    width: 50px;
    padding: 15px;
    background-color: #26345a;
    border-radius: 50%;
}

.logo__bar {
    height: 1px;
    width: 100%;
    background-color: #19213a;
    box-shadow: 0px 1px 0px 0px #26345a;
}

.form {
    margin: 0 35px 15px 35px;
    display: grid;
    grid-gap: 10px;
    align-items: center;
}

.input {
    background-color: #1c212d;
    border: none;
    border-radius: 5px;
    height: 50px;
    width: 100%;
    font-size: 14px;
    color: #ffffff;
    padding: 3px 15px;
    font-family: var(--font);
    box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid var(--red);
    transition: border-bottom 100ms linear;
}

.input.--valid {
    border-bottom: 1px solid var(--green);
}

.input.--email {
    grid-area: email;
}
.input.--password {
    grid-area: password;
}
.input.--submit {
    grid-area: submit;
}
.input.--first-name {
    grid-area: first-name;
}
.input.--last-name {
    grid-area: last-name;
}
.input.--password2 {
    grid-area: password2;
}

.secondary-button {
    background: transparent;
    border: none;
    color: white;
    font-size: 16px;
    text-align: left;
    font-family: var(--font);
    cursor: pointer;
    transition: color 200ms linear;
}

.secondary-button:hover {
    color:#54d766;
}

.secondary-button.--forgot {
    grid-area: forgot;
}

.submit {
    grid-area: submit;
    background-color: #54d766;
    border: none;
    font-size: 14px;
    color: white;
    max-width: 145px;
    width: 100%;
    height: 45px;
    border-radius: 55px;
    font-family: var(--font);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.submit:hover {
    background-color: #2c9e3b;
}

.submit.--disabled {
    background-color: #171f37;
    cursor: default;
    pointer-events: none;
}


.footer {
    background-color: #171f37;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px 10px;
    color: #c7d6ff;
    font-size: 14px;
    text-align: center;
    width: 100%;
    justify-self: flex-end;
}

.footer__button {
    border: none;
    background: transparent;
    color: #54d766;
    border-bottom: 1px solid  #54d766;
    padding: 0;
    margin-left: 10px;
    padding-bottom: 2px;
    font-size: 14px;
    font-family: var(--font);
    cursor: pointer;
}

@media (max-width: 1100px) {
    .form {
        margin: 5px;
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .form > * {
        margin: 5px 0;
    }
    .card {
        padding-top: 5px;
    }
}

@keyframes message-animation {

    0% {
        color: transparent;
        border: 1px solid transparent;
    }

    50% {
        color: var(--t-color-1);
        border: 1px solid var(--t-color-1);
    }
    100% {
        color: var(--t-color-2);
        border: 1px solid var(--t-color-2);
    }
    
}

.message {
    grid-area: message;
    margin: 0;
    font-size: var(--t-size-1);
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    color: var(--t-color-2);
    border: 1px solid transparent;
    height: 20px;
    transition: all 200ms linear;
}

.message.--valid {
    grid-area: message;
    font-size: var(--t-size-1);
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    animation: message-animation 2s;
    color: var(--t-color-2);
    border: 1px solid var(--t-color-2);
}