.verify__form {
    grid-template-areas: 'message message' 'resend resend' '. submit';
    grid-template-columns: 2fr 1fr;
}

.primary-button {
    width: 100%;
    font-size: 22px;
    color: #54d766;
    background-color: transparent;
    border: none;
    font-family: var(--font);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 10px 0;
    border: 2px solid  #54d766;
    border-radius: 50px;
    padding: 5px 0;
}

.primary-button.--resend {
    grid-area: resend;
}
