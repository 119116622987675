.signup__form {

    grid-template-areas:    'first-name     last-name   last-name'
                            'email          email       email'
                            'password       password    password'
                            'password2      password2   password2'
                            'agreement      agreement   submit'
                            'message        message     message';
    grid-template-columns: 6fr 1fr 4fr;
}

.agreement {
    grid-area: agreement;
    display: grid;
    grid-template-columns: 20px auto;
    grid-gap: 10px;
    font-size: 10px;
}

.agreement__checkbox {
    height: 20px;
    width: 20px;
    border: 1px solid white;
    margin-right: 20px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    pointer-events: all;
}

.agreement__message  a, .agreement__message  a:visited, .agreement__message  a:active {
    color: var(--green);
    text-decoration: none;
}
